import React from 'react';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';

import styled from '@emotion/styled';
import { slide as Menu } from 'react-burger-menu';
import '../styles/offcanvas.scss';

import logo from '../images/dpm_logo_v2.svg';
import logoWhite from '../images/dpm_logo_white.svg';
import burgerTeal from '../images/burger-teal.svg';
import close from '../images/iconfinder_close_1814098.svg';

// Make the nav headings bold

class NavComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offCanvasOpen: false,
      logoOpacity: true,
      logoShow: true,
    };
  }

  toggleMenu = () => {
    return this.setState(prevState => {
      return { offCanvasOpen: !prevState.offCanvasOpen };
    });
  };

  listenToScroll = () => {
    let logoOpacity = true;
    let logoShow = true;

    const scrolled =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (scrolled > 30) logoOpacity = false;
    if (scrolled > 200) logoShow = false;
    return this.setState({ logoOpacity, logoShow });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  handleCategoryChange = cat => {
    this.props.resetProperties();
    this.props.applyFilter(cat.value ? cat : null);
    if (!this.props.isHome) navigate('/#properties');
    else this.setState({ offCanvasOpen: false });
  };

  render() {
    const categories = [
      { value: null, label: 'All' },
      { value: 'apartment', label: 'Apartment' },
      { value: 'unit', label: 'Unit' },
      { value: 'house', label: 'House' },
      { value: 'commercial', label: 'Commercial' },
    ];
    const everythingMenuLinks = [
      {
        label: 'About Us',
        link: '/about',
      },
      {
        label: 'Services',
        link: '/about#services',
      },
      {
        label: 'Team',
        link: '/about#team',
      },
      {
        label: 'Maintenance',
        link: '/maintenance',
      },
      {
        label: 'Offices',
        link: '/offices',
      },
      {
        label: 'Contact',
        link: '/contact',
      },
    ];
    // Notice that we're using singleProperty versus Home for the prop, cause I'm lazy.
    // Margin should be applied from the top of the nav for all non single property pages, initially this was coded just for thehome page.

    return (
      <>
        <StyledNav
          className={'navbar' + (this.props.singleProperty ? '' : ' home')}
          role="navigation"
          aria-label="main navigation"
        >
          <div className="container">
            <button onClick={this.toggleMenu} className="navButton">
              <img src={burgerTeal} />
            </button>
            <Link
              to="/"
              className={'logo-link ' + (!this.state.logoShow ? 'hidden' : '')}
            >
              <img
                src={logo}
                alt="Logo"
                className={!this.state.logoOpacity ? 'invisible' : ''}
              />
            </Link>
          </div>
        </StyledNav>
        <Menu
          customBurgerIcon={false}
          customCrossIcon={false}
          isOpen={this.state.offCanvasOpen}
          width={'50vw'}
          noOverlay
          right
          menuClassName={'custom-offcanvas'}
        >
          <CloseButton onClick={this.toggleMenu}>
            <img src={close} style={{ width: 40, height: 40, color: '#000' }} />
          </CloseButton>
          <StyledOffCanvas>
            <div className="menu-container">
              <div className="columns is-mobile">
                <div className="column is-half">
                  <hr />
                  <h3 className="title-with-hr is-uppercase is-size-7">properties</h3>
                  <ul>
                    {categories.map(c => (
                      <li key={c.value} className="is-size-7" style={{ color: '#999' }}>
                        <a
                          onClick={() => this.handleCategoryChange(c)}
                          className="menu-item"
                        >
                          {c.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <p>
                    {this.props.isHome ? (
                      <a
                        className="is-size-7"
                        onClick={() => this.setState({ offCanvasOpen: false })}
                      >
                        home
                      </a>
                    ) : (
                        <Link to="/" className="is-size-7"  style={{ marginTop: '1em' }}>
                        Home
                      </Link>
                    )}
                  </p>
                </div>
                <div className="column is-half">
                  <hr />
                  <h3 className="title-with-hr  is-uppercase is-size-7">other</h3>
                  <ul>
                    {everythingMenuLinks.map(({ link, label }) => (
                      <li key={link} className="is-size-7" style={{color: '#999'}}>
                        <Link
                          to={link}
                          onClick={() =>
                            this.setState({ offCanvasOpen: false })
                          }
                          className="menu-item"
                        >
                          {label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </StyledOffCanvas>
        </Menu>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetProperties: () => {
    return dispatch({ type: `RESET` });
  },
  applyFilter: value => {
    return dispatch({
      type: `FILTER_FROM_NAV`,
      key: 'category',
      value,
    });
  },
});

const Nav = connect(null, mapDispatchToProps)(NavComponent);

export default Nav;

const StyledOffCanvas = styled('div')`
  p {
    margin-bottom: 0;
  }
  &:focus {
    outline: none;
  }
  display: flex !important;
  height: 100%;
  justify-content: center;
  align-items: center;
  .column {
    padding: 1em;
  }

  a {
    text-transform: uppercase;
    transition: 0.5s;
    font-weight: 500;
    color: #999;
    &:hover {
      color: #000;
    }
  }
  ul {
    margin: 20px 8px;
    margin-right: 0;
    li {
    }
  }
  & > div {
    &:hover {
      a {
        transition: 0.5s;
        opacity: 0.33;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .menu-container {
    @media screen and (max-width: 767px) {
      width: 80%;
    }
  }

  @media screen and (max-width: 767px) {
    li {
      margin-bottom: 0.25em;
    }
    p {
      margin-top: 1em;
    }
  }
`;

const CloseButton = styled('button')`
  position: fixed;
  top: 2.5rem;
  right: 3rem;
  @media screen and (max-width: 960px) {
    top: 1.5rem;
    right: 1.5rem;
  }
  background: none;
  border: none;
  padding: 0;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
`;
const StyledNav = styled('div')`
  z-index: 8;
  position: fixed;
  width: 100%;
  height: 0;
  min-height: 0;
  overflow: visible;
  
  @media screen and (max-width: 960px) {
    padding: 0 1.5rem;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 0;
    height: 0;
    overflow: visible;
  }
  .logo-link {
    top: 0;
    width: 100px;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: inline-block;
    position: fixed;
    cursor: pointer;
    @media screen and (max-width: 960px) {
      width: 90px;
      margin-top: 20px;
    }
    @media screen and (max-width: 520px) {
      margin-top: 0;
    }

    &.hidden {
      display: none;
    }
    img {
      width: 80px;
      transition: 0.333s;
      opacity: 1;

      @media screen and (max-width: 960px) {
        width: 60px;
      }

      z-index: 9;
      &.invisible {
        opacity: 0;
      }
    }
  }
  .navButton {
    top: 0;
    &:hover {
      cursor: pointer;
    }
    margin-top: 30px;

    height: 45px;
    width: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      width: 25px;
      height: 25px;
    }
    @media screen and (max-width: 960px) {
      margin-top: 29px;
      margin-right: 17px;
      img {
        margin-right: 2px;
        width: 20px;
        height: 20px;
      }
    }
    @media screen and (max-width: 520px) {
      margin-top: 10px;
      margin-right: 0;
      img {
        margin-right: 0;
        width: 20px;
        height: 20px;
        display: relative;
      }
    }
    background: none;
    border: none;
    padding: 0;
    z-index: 9;
    position: absolute;
    right: 0;
  }

  &.home {
    .navButton,
    .logo-link {
      @media screen and (max-width: 520px) {
        margin-top: 26px;
      }
    }
    .logo-link {
      @media screen and (max-width: 520px) {
        margin-top: 20px;
      }
    }
  }
`;
