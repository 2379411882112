import React from 'react';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import logo from '../images/dpm_logo_v2.svg';

const FooterComponent = ({
  category,
  resetProperties,
  applyFilter,
  isHome = false,
}) => {
  const handleCategoryChange = cat => {
    resetProperties();
    applyFilter(cat.value ? cat : null);
    if (!isHome) navigate('/#properties');
    else {
      navigate('#properties');
    }
  };
  const categories = [
    { value: null, label: 'All' },
    { value: 'apartment', label: 'Apartment' },
    { value: 'unit', label: 'Unit' },
    { value: 'house', label: 'House' },
    { value: 'commercial', label: 'Commercial' },
    
  ];
  const everythingMenuLinks = [
    {
      label: 'About Us',
      link: '/about',
    },
    {
      label: 'Services',
      link: '/about#services',
    },
    {
      label: 'Team',
      link: '/about#team',
    },
    {
      label: 'Maintenance',
      link: '/maintenance',
    },
    {
        label: 'Offices',
        link: '/offices',
      },
    {
      label: 'Contact',
      link: '/contact',
    },
  ];

  return (
    <StyledFooter className="footer">
      <div className="container">
        <div className="columns">
          <div className="column is-half">
            <div className="columns is-mobile">
              <div className="column is-half">
                <hr />
                <h3 className="title-with-hr is-size-7">properties</h3>
                <ul>
                  {categories.map(c => {
                    if (c.value == null && category == null && isHome) return;

                    return (
                      <li key={c.value} className='is-size-7'>
                        <a
                          onClick={() => handleCategoryChange(c)}
                          className="menu-item"
                        >
                          {c.label}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="column is-half">
                <hr />
                <h3 className="title-with-hr is-size-7" >other</h3>
                <ul>
                  {everythingMenuLinks.map(({ link, label }) => (
                    <li key={link} className="is-size-7">
                      <Link to={link} className="menu-item">
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="columns second-row">
          <div className="column is-one-quarter">
            <hr />
            <h3 className="title-with-hr is-size-7">location</h3>
            <p className="is-size-7">
              <span className="is-uppercase">
                Commerce Building - Level 2<br />
                2 Dowling Street
                <br />
                City Centre
                <br />
                Dunedin, 9016
              </span>
            </p>
          </div>
          <div className="column is-one-quarter">
            <hr />
            <h3 className="title-with-hr is-size-7">contact</h3>
            <p className="is-size-7">
              <a href="tel:+6434701122">03 470 1122</a>
              <br />
              <a href="mailto:contact@dpmgroup.nz">contact@dpmgroup.nz</a>
            </p>
          </div>
          <div className="column is-one-half logo-column">
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            <p className="is-size-7 is-uppercase">
              <span>
                &copy; 2020 DPM Property Group Limited MREINZ | All rights
                reserved |
              </span>{' '}
              <Link to="/privacy-terms">privacy policy &amp; terms</Link>
              <span> | developed by </span>
              <a href="https://carlaiau.com/" target="_blank" rel="noreferrer">
                carl aiau
              </a>
            </p>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

const mapStateToProps = ({ category }) => ({ category });

const mapDispatchToProps = dispatch => ({
  resetProperties: () => {
    return dispatch({ type: `RESET` });
  },
  applyFilter: value => {
    return dispatch({
      type: `FILTER_FROM_NAV`,
      key: 'category',
      value,
    });
  },
});

const Footer = connect(mapStateToProps, mapDispatchToProps)(FooterComponent);

export default Footer;

const StyledFooter = styled('div')`
  padding-top: 4.5rem;
  @media screen and (max-width: 768px) {
    padding-bottom: 3rem;
  }
  background-color: #efefef;
  .columns {
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }
  }
  p {
    color: #999;
    margin: 8px;
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 0;
    }
    a {
      color: #999;
      transition: 0.5s;
      &:hover {
        color: #000;
        opacity: 1;
      }
    }
  }
  h3 {
    color: #000;
    text-transform: uppercase;
  }
  ul {
    margin-top: 1em;
    li {
      margin: 0 8px;
      text-transform: uppercase;
      a {
        text-decoration: none;
        color: #999;
        &:hover {
          color: #000;
          opacity: 1;
        }
      }
    }
  }

  form {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    .button {
      margin-left: 20px;
    }
    .input {
      border: none;
      box-shadow: none;
      color: #000;
      border-bottom: 1px solid #fff;
      transition: 0.5s;
      font-size: 16px;
      &:focus {
        border-bottom: 1px solid #000;
      }
      &::placeholder {
        color: #000;
        opacity: 0.5;
      }
    }
  }
  .second-row {
    @media screen and (min-width: 769px) {
      margin-top: 0;
    }
  }
  img {
    max-width: 100px;
  }
  .logo-column {
    @media screen and (min-width: 769px) {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    @media screen and (max-width: 768px) {
      margin-left: 8px;
      margin-top: 5vh;
    }
  }
  .is-grey {
    opacity: 0.5;
  }
  .is-lowercase {
    text-transform: lowercase;
  }
`;
