import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/style.scss';
import Nav from './nav';
import Footer from './footer';
const Layout = ({
  singleProperty = false,
  children,
  isHome = false,
  title = false,
  description = false,
}) => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="stylesheet"
          href="https://www.dpmgroup.nz/fonts/784603/CBB2373F2B6A84526.css"
        />
        <title>
          {title ? title : 'DPM | First Class Property Management (Dunedin)'}
        </title>
        <meta
          name="description"
          content={
            description
              ? description
              : 'As Property Investors ourselves, we set the benchmark high as to what is expected of your Property Manager - feel confident your property is in safe hands.'
          }
        />
      </Helmet>
      {singleProperty ? <Nav singleProperty={true} /> : <Nav isHome={isHome} />}
      {children}
      <Footer isHome={isHome} />
    </>
  );
};

export default Layout;
